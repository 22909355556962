import {createContext, ReactNode, useContext, useEffect, useMemo} from "react"
import {AnalyticsBrowser} from "@segment/analytics-next"
import {useIsClient} from "usehooks-ts"
import {Analytics} from "~/analytics/Analytics"

interface Profile {
  id: string;
  created_at: string;
  email: string;
  name: string | null;
  feature_flags?: { [key: string]: boolean } | null | undefined;
}

interface AnalyticsContextValue {
  analytics: Analytics;
}

const AnalyticsContext = createContext<AnalyticsContextValue | null>(null)

export const AnalyticsContextProvider = ({
  writeKey,
  profile,
  children,
}: {
  writeKey: string;
  profile: Profile | null;
  children?: ReactNode;
}) => {
  const isClient = useIsClient()

  // Create or retrieve the AnalyticsBrowser instance only on the client.
  const analyticsBrowserInstance = useMemo(() => {
    if (isClient) {
      return AnalyticsBrowser.load({writeKey})
    }
    return null
  }, [isClient, writeKey])

  const analytics = useMemo(() => new Analytics(analyticsBrowserInstance), [
    analyticsBrowserInstance,
  ])

  useEffect(() => {
    if (isClient && profile && analytics) {
      analytics.reset().then(() => {
        analytics.identify(profile.id, profile)
      })
    }
  }, [isClient, profile, analytics])

  return (
    <AnalyticsContext.Provider value={{analytics}}>
      {children}
    </AnalyticsContext.Provider>
  )
}

export const useAnalyticsContext = () => {
  const context = useContext(AnalyticsContext)
  if (!context)
    throw new Error("You can only use AnalyticsContext within its provider")
  return context
}

export const useTrackIdentity = (userId: string) => {
  const {analytics} = useAnalyticsContext()
  return analytics.identify(userId)
}

export const useTrackPage = (pageName: string, data?: any) => {
  const isClient = useIsClient()
  const {analytics} = useAnalyticsContext()
  useEffect(() => {
    if (isClient) {
      analytics.page(pageName, data)
    }
  }, [isClient])
}

export const useTrackEvent = (name: string, data?: any) => {
  const {analytics} = useAnalyticsContext()
  return analytics.track(name, data)
}

export const useTrackLogout = () => {
  const {analytics} = useAnalyticsContext()
  return analytics.reset()
}