import {AnalyticsBrowser} from "@segment/analytics-next"

export class Analytics {
  private instance: AnalyticsBrowser | null

  constructor(instance: AnalyticsBrowser | null) {
    this.instance = instance
  }

  identify(userId: string, traits?: any) {
    return this?.instance?.identify(userId, traits) ?? Promise.resolve()
  }

  page(pageName: string, data?: any) {
    return this?.instance?.page(pageName, data) ?? Promise.resolve()
  }

  track(event: string, properties?: any) {
    return this?.instance?.track(event, properties) ?? Promise.resolve()
  }

  reset() {
    return this?.instance?.reset() ?? Promise.resolve()
  }
}